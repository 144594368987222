import ButtonAppBar from "../../utils/AppBar";
import React, { ReactNode } from 'react';

export interface AppLayoutWithBreadcrumbsProps {
    component : ReactNode
}

export default function AppLayoutWithBreadcrumbs(props: AppLayoutWithBreadcrumbsProps ) {
    const component = props.component;

    return(
        <div>
            <ButtonAppBar/>
             {component}
        </div>
    )
}
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { Button, createTheme, CssBaseline, ThemeProvider } from '@mui/material';
import type {} from '@mui/x-data-grid/themeAugmentation';
import { useNavigate } from 'react-router-dom';

const theme = createTheme({
    palette: {
        primary: { main: '#29648A' },
        secondary: { main: '#2e9cca' },
        background: {
            default: '#25274D',
            paper: '#464866',
        },
        text: {
            primary: '#E1E1F3',
            secondary: '#AAABB8',
        },
    },
    mixins: {
        MuiDataGrid: {
            containerBackground: '#464866',
        }
    }
});

function ViewCustomers() {
    const [customerData, setCustomerData] = useState<ViewCustomerModel[]>([]);

    const navigate = useNavigate();

    useEffect(() => {
        async function getCustomer() {
            const response = await fetch(process.env.REACT_APP_GET_CUSTOMER, {
                method: "GET",
                headers: {
                    "Access-Control-Allow-Origin": "*",
                    "Content-type": "application/json",
                },
            });
            const data = await response.json();
            setCustomerData(data);
            console.log(data);
        }
        getCustomer();
    }, []);

    const columns: GridColDef<(typeof customerData)[number]>[] = [
        { field: 'customerId', headerName: 'Customer ID'},
        { field: 'customerName', headerName: 'Customer Name', width: 200, editable: true },
        { field: 'customerEmail', headerName: 'Customer Email', width: 300, editable: true },
        { field: 'customerAddress', headerName: 'Customer Address', width: 400, editable: true },
        { field: 'customerPhone', headerName: 'Customer Phone', width: 150, editable: true },
        { field: 'createProposal', 
            headerName: 'Create Proposal', 
            width: 150,
            renderCell: (customer) => {
                return (<Button 
                            variant='contained'
                            onClick={() => {
                                navigate('/create_proposal', { state: JSON.stringify(customer.row)})}}>
                            Create</Button>)
            }     
        }
    ];

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <Box sx={{ height: 400, width: '100%', padding: 2 }}>
                <DataGrid
                    getRowId={(row) => row.customerId}
                    rows={customerData}
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                                pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5]}
                    checkboxSelection
                    disableRowSelectionOnClick
                />
            </Box>
        </ThemeProvider>
    );
}

export default ViewCustomers;

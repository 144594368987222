import { Box, Button, createTheme, CssBaseline, TextField, ThemeProvider } from '@mui/material';
import React, { useState } from 'react';
import Customer, { CustomerResponse } from './AddCustomerModel';

const theme = createTheme({
    palette: {
      primary: {
        main: '#29648A',
      },
      secondary: {
        main: '#2e9cca',
      },
      background: {
        default: '#25274D',
        paper: '#464866',
      },
      text: {
        primary: '#E1E1F3',
        secondary: '#AAABB8',
      }
    },
  });

export default function AddCustomer() {

    const [formData, setFormData] = useState<Customer>(
        {
            customerName: '',
            customerEmail: '',
            customerPhone: '',
            customerAddress: ''
        }
    )

    async function addCustomer() {
        try {
            const response = await fetch(process.env.REACT_APP_ADD_CUSTOMER, {
                method: "POST",
                headers: {
                "Access-Control-Allow-Origin" : "*",
                "Content-type": "application/json",
                },
                body: JSON.stringify(formData),
            });
            const responseJson = (await response.json()) as CustomerResponse;
            alert(responseJson.status)
        } catch (error) {
            alert(error)
        }
    }

    // To update the state
    const updateForm = (field: keyof Customer, e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFormData(prev => ({ ...prev, [field]: e.target.value }));
    };

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Box
            component="form"
            sx={{ '& .MuiTextField-root': { marginRight: 2, marginBottom: 2, width: '25ch' }, marginTop: 5, marginLeft: 5, width:500}}
            noValidate
            autoComplete="off"
            >
            <div>
                <TextField
                required
                id="customerName"
                label="Name"
                variant='filled'
                onChange={e => updateForm('customerName',e)}
                />
                <TextField
                required
                id="customerEmail"
                label="Email Address"
                type="email"
                variant='filled'
                onChange={e => updateForm('customerEmail',e)}
                />
                <TextField
                required
                id="customerPhone"
                label="Phone Number"
                type="tel"
                variant='filled'
                onChange={e => updateForm('customerPhone',e)}
                />
                <TextField
                required
                id="customerAddress"
                label="Address"
                variant='filled'
                onChange={e => updateForm('customerAddress',e)}
                />
            </div>
            <Button 
                    color='primary'
                    variant="contained"
                    sx={{marginTop:2 }}
                    onClick={async() => addCustomer()}
            >Add Customer</Button>
            </Box>
        </ThemeProvider>
        
      );

}
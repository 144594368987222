import { useEffect } from "react"
import React, { useState } from 'react';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { createTheme, CssBaseline, ThemeProvider } from "@mui/material";
import type {} from '@mui/x-data-grid/themeAugmentation';

const theme = createTheme({
    palette: {
      primary: {
        main: '#29648A',
        },
        secondary: {
        main: '#2e9cca',
        },
      background: {
        default: '#25274D',
        paper: '#464866',
      },
      text: {
        primary: '#E1E1F3',
        secondary: '#AAABB8',
      }
    },
    mixins : {
        MuiDataGrid: {
            containerBackground: '#464866',
        }   
    }
  });

function ViewProposal() {
    
    const [proposalData, setProposalData] = useState<Proposal[]>([])

    useEffect(() => {
        async function getProposal() {
            const response = await fetch(process.env.REACT_APP_GET_PROPOSAL, {
            method: "GET",
            headers: {
            "Access-Control-Allow-Origin" : "*",
            "Content-type": "application/json",
            },
        })
        const data = await response.json()
        setProposalData(data)
        console.log(data)
        console.log(typeof proposalData)
        }
        getProposal()
    }, [])

    const columns: GridColDef<(typeof proposalData)[number]>[] = [
        { field: 'proposalId', headerName: 'Proposal ID', width: 100 },
        { field: 'customerName', headerName: 'Customer Name', width: 150, editable: true },
        { field: 'customerAddress', headerName: 'Customer Address', width: 200, editable: true },
        { field: 'areaRennovated', headerName: 'Area Renovated', width: 150, editable: true },
        { field: 'areaOfConcreteInstallation', headerName: 'Concrete Installation Area', type: 'number', width: 200, editable: true },
        { field: 'areaOfDemolition', headerName: 'Demolition Area', type: 'number', width: 150, editable: true },
        { field: 'thicknessOfConcrete', headerName: 'Concrete Thickness', width: 150, editable: true },
        { field: 'concretePsiStrength', headerName: 'Concrete PSI Strength', type: 'number', width: 200, editable: true },
        { field: 'controlJointMaterial', headerName: 'Control Joint Material', width: 200, editable: true },
        { field: 'rebarNumber', headerName: 'Rebar Number', type: 'number', width: 150, editable: true },
        { field: 'rebarSpacing', headerName: 'Rebar Spacing', type: 'number', width: 150, editable: true },
        { field: 'surfaceFinishing', headerName: 'Surface Finishing', width: 150, editable: true },
        { field: 'additionalNotes', headerName: 'Additional Notes', width: 250, editable: true },
        { field: 'installationCost', headerName: 'Installation Cost', type: 'number', width: 150, editable: true },
        { field: 'isDemolitionIncluded', headerName: 'Demolition Included', type: 'boolean', width: 150, editable: true },
        { field: 'demolitionCost', headerName: 'Demolition Cost', type: 'number', width: 150, editable: true },
        { field: 'isDisposalIncluded', headerName: 'Disposal Included', type: 'boolean', width: 150, editable: true },
        { field: 'disposalCost', headerName: 'Disposal Cost', type: 'number', width: 150, editable: true },
        { field: 'isHoaAssistanceIncluded', headerName: 'HOA Assistance Included', type: 'boolean', width: 200, editable: true },
        { field: 'hoaAssistanceCost', headerName: 'HOA Assistance Cost', type: 'number', width: 200, editable: true },
        { field: 'hasSpecialDesign', headerName: 'Special Design', type: 'boolean', width: 150, editable: true },
        { field: 'specialDesignCost', headerName: 'Special Design Cost', type: 'number', width: 150, editable: true },
        { field: 'projectDuration', headerName: 'Project Duration', width: 150, editable: true },
        { field: 'isFinance', headerName: 'Finance', type: 'boolean', width: 150, editable: true },
        { field: 'monthlyCost', headerName: 'Monthly Cost', type: 'number', width: 150, editable: true },
        { field: 'isDiscount', headerName: 'Discount', type: 'boolean', width: 150, editable: true },
        { field: 'discountAmount', headerName: 'Discount Amount', type: 'number', width: 150, editable: true },
        { field: 'discountType', headerName: 'Discount Type', width: 150, editable: true },
        { field: 'hasTentativeDates',
             headerName: 'Tentative Dates',
              type: 'boolean', 
              width: 150, 
              editable: true
            },
        { field: 'startDate',
            headerName: 'Start Date', 
            type: 'date', 
            width: 150, 
            editable: true,
            valueGetter: params => new Date(params),  
        },
        { field: 'endDate', 
            headerName: 'End Date', 
            type: 'date', 
            width: 150, 
            editable: true,
            valueGetter: params => new Date(params),
        },
        { field: 'imageByteArray', headerName: 'Image Byte Array', width: 200, editable: true },
      ];      
    
    return(
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            <Box sx={{ height: 400, width: '100%', padding:2 }}>
            <DataGrid
                getRowId={(row) => row.proposalId}
                rows={proposalData}
                columns={columns}
                initialState={{
                pagination: {
                    paginationModel: {
                    pageSize: 5,
                    },
                },
                }}
                pageSizeOptions={[5]}
                checkboxSelection
                disableRowSelectionOnClick
            />
            </Box>
        </ThemeProvider>
    )
}

export default ViewProposal
import { useNavigate } from 'react-router-dom';
import React, { useState , useEffect } from 'react';

function LandingPage() {
    const navigate = useNavigate();

    useEffect(() =>
        {
            if(!("token" in sessionStorage) || sessionStorage.getItem('token') != process.env.REACT_APP_TOKEN) {
                navigate("/login");
            }
            else {
                navigate("/home");
            }
        }
    )
}

export default LandingPage;
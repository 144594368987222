import React from "react";
import ActionAreaCard from "../../utils/ActionAreaCard";
import { Box } from "@mui/material";

export default function HomePage () {
    return (
        <>
        <Box component="section" sx={{ marginTop: 5, marginLeft: 5 }}>
            <ActionAreaCard primaryText="Add Customers" 
                            secondaryText="Add new customers to keep track of"
                            page="/add_customer"
            ></ActionAreaCard>
        </Box>
        <Box component="section" sx={{  marginTop: 2, marginLeft: 5}}>
            <ActionAreaCard primaryText="View Customers" 
                            secondaryText="View customers that are interested in concrete proposals"
                            page="/view_customers"
            ></ActionAreaCard>
        </Box>
        <Box component="section" sx={{ marginTop: 2, marginLeft: 5 }}>
            <ActionAreaCard primaryText="Create Proposal" 
                            secondaryText="Create Project Proposals to send to customers."
                            page="/create_proposal"
            ></ActionAreaCard>
        </Box>
        <Box component="section" sx={{ marginTop: 2, marginLeft: 5 }}>
            <ActionAreaCard primaryText="View Created Proposal" 
                            secondaryText="View the proposals that have been generated."
                            page="/view_proposals"
            ></ActionAreaCard>
        </Box>
        </>
    )
}